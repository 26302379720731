import React, { useState, useEffect } from "react";
import "./TypingMessage.css";

const TypingMessage = ({ message, typingSpeed = 50, scrollToBottom }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const [typingComplete, setTypingComplete] = useState(false);

  const parseLinks = (text) => {
    if (!text) {
      return text;
    }

    const mdImgRegex = /((?:\!?\[.*\]\()(?:(?:http|ftp|https):\/\/(?:[\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-]\.(?:gif|jpe?g|tiff?|png|webp|bmp)))\))/g;
    const urlRegex = /((?:http|ftp|https):\/\/(?:[\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-]))/g;
    const imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

    return text.split(mdImgRegex).map((part, i) => {

      if (part && part.match(mdImgRegex)) {
        var src = part.match(urlRegex)[0];
        return (
          <img
            key={i}
            src={src.startsWith("http") ? src : "http://" + src}
            alt={part.match(/(?:\[)(.*)(?=\]\()/g)[0]}
          />
        );
      } else if (part && part.match(urlRegex)) {
        return part.split(urlRegex).map((part2, j) => {
          if (part2 && part2.match(urlRegex)) {
            if (part2.match(imageRegex)) {
              return (
                <img
                  key={j}
                  src={part2.startsWith("http") ? part2 : "http://" + part2}
                  alt=""
                />
              );
            } else {
              return (
                <a
                  key={j}
                  href={part2.startsWith("http") ? part2 : "http://" + part2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part2}
                </a>
              );
            }
          } else return part2;
        });
      } else {
        return part;
      }
    });
  };

  const replaceNewlines = (text) => {
    const lines = text.split("\n");
    return lines.map((line, i) => (
      <React.Fragment key={i}>
        {parseLinks(line)}
        {i < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const typeMessage = async () => {
      if (message.role === "system") {
        setDisplayedText(replaceNewlines(message.content));
        setTypingComplete(true);
        setShowCursor(false);
        scrollToBottom();
        return;
      }

      let currentText = "";
      for (const char of message.content.split(" ")) {
        await new Promise((resolve) => setTimeout(resolve, typingSpeed));
        currentText += char + " ";
        setDisplayedText(replaceNewlines(currentText));
        scrollToBottom();
      }
      setTypingComplete(true);
      setShowCursor(false);
      setTimeout(()=>scrollToBottom(true), 100);
    };

    typeMessage();
  }, [message.content, typingSpeed, message.role]);

  useEffect(() => {
    if (message.role === "system") return;

    const cursorBlink = setInterval(() => {
      if (typingComplete) {
        setShowCursor(false);
        clearInterval(cursorBlink);
      } else {
        setShowCursor((prevState) => !prevState);
      }
    }, 500);

    return () => {
      clearInterval(cursorBlink);
    };
  }, [message.role, typingComplete]);

  return (
    <div className={`chat-message ${message.role}`}>
      {displayedText}
      {showCursor && <span className="typing-cursor">|</span>}
    </div>
  );
};

export default TypingMessage;
