import React, { useState, useEffect } from "react";
import axios from "axios";
import { CONFIG } from "./config"

const CaptchaController = ({ onTokenCreated }) => {
  const [captchaSolution, setCaptchaSolution] = useState("");
  const [token, setToken] = useState("");

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(CONFIG.recaptchaKey, { action: "homepage" })
        .then((_captchaSolution) => {
          setCaptchaSolution(_captchaSolution);
          createToken(_captchaSolution);
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render="+CONFIG.recaptchaKey;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  const createToken = async (_captchaSolution) => {
    try {
      // Make new token  
      const url = `${CONFIG.APIDomain}createToken?g-recaptcha-response=${_captchaSolution}`;
      const response = await axios.post(url, '', { withCredentials: true });
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
      setToken(response.data.token);
      onTokenCreated(response.data.token);
    } catch (error) {
      console.error("Error creating token:", error);
    }
  };

  return (
    <div>
      <div
        className="g-recaptcha"
        data-sitekey={CONFIG.recaptchaKey}
        data-size="invisible"
      ></div>
    </div>
  );
};

export default CaptchaController;
