import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./App.css";
import TypingMessage from "./TypingMessage";
import CaptchaController from "./CaptchaController";
import { CONFIG } from "./config";

// TODO
// Deploy to main domain

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const [token, setToken] = useState("");

  const handleTokenCreated = (createdToken) => {
    setToken(createdToken);
    console.log(createdToken);
  };

  const scrollToBottom = (smooth) => {
    messagesEndRef.current.scrollIntoView({ behavior: smooth ? "smooth" : "auto", block: "end" });
  };

  const handlePresetMessage = (presetMessage) => {
    setInput(presetMessage);
    handleSubmit(new CustomEvent("submit", { detail: presetMessage }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log(messages);
    if (!input && !e.detail) return;
    if (isLoading || errorMessage) return;

    setIsLoading(true);
    setMessages([
      ...messages,
      { role: "user", content: !input ? e.detail : input },
    ]);
    setInput("");

    scrollToBottom(true);
    try {
      if (!token) throw new Error("No token");
      window.gtag('event', 'message', { 'text': !input ? e.detail : input });
      const response = await axios.post(
        CONFIG.APIDomain + "myFunction1", // Replace this with your API endpoint
        {
          messages: [
            ...messages,
            { role: "user", content: !input ? e.detail : input },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data &&
        response.data.response &&
        response.data.response.length > 0
      ) {
        console.log(response.data.response);
        const completion = response.data.response.trim();
        setMessages([
          ...messages,
          { role: "user", content: !input ? e.detail : input },
          { role: "assistant", content: completion },
        ]);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error fetching ChatGPT completion:", error);
      setMessages([...messages]);
      if (error.message == "No token") {
        setErrorMessage("Something went wrong with authorization. Try refreshing the page")
      }
      if (error.response.status == 429) {
        setErrorMessage("Sorry, I've limited the number of messages to 30 per day. Come back in 24 hours!")
      }
    } finally {
      setIsLoading(false);
    }
  };

  const presetMessages = [
    "Who is Philipp P?",
    "What does Philipp P do?",
    "What is Philipp P known for?",
    "Where was Philipp P published?",
    "What do Philipp P's designs look like?",
    "Tell me about a couple of Philipp P's projects with pictures",
    "Give some links to Philipp P's resources",
    "How to contact Philipp P?",
    "What's so special about Philipp P's work?",
    "What's Philipp P's design philosophy?",
    "Is there anything that Philipp P cannot do?",
    "How is Philipp P breaking the rules of industrial design?",
    "What can the industry learn from Philipp P?",
    "What inspired Philipp P to become a designer?",
    "Why should I trust Philipp P to do design work?",
    "Why is Philipp P's work considered a mark of excellence and quality?",
    "Can you describe a specific project that Philipp P has worked on that showcases his design skills?",
    "Is it a cult of Philipp P here?",
    // Add more preset messages as desired
  ];

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className="App">
      <h1>Let's talk about Philipp P.</h1>
      <sub>
        *Keep in mind that the links are not always accurate and that it
        sometimes pretends that it doesn't show pictures
      </sub>
      <div className="chat-container">
        {messages.map((message, index) => (
          <TypingMessage
            key={index}
            message={message}
            scrollToBottom={() => scrollToBottom()}
          />
        ))}
        {isLoading && (
          <img className="loading-indicator" src="/img/cursor.gif" />
        )}
        {errorMessage && (
          <div className={`chat-message error`}>{errorMessage}</div>
        )}
      </div>
      <form className="chat-input" onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type and press Enter to send"
          ref={inputRef}
        />
      </form>
      <CaptchaController onTokenCreated={handleTokenCreated} />
      <div ref={messagesEndRef} />
      <div className="preset-buttons">
        {presetMessages.map((message, index) => (
          <button
            key={index}
            onClick={() => handlePresetMessage(message)}
            className={"chat-message user"}
          >
            {message}
          </button>
        ))}
      </div>
    </div>
  );
}

export default App;
